<template>
  <div v-bind:class="[{'text-align':$i18n.locale == 'ar'},'peers' ,'a' ,'i-s', 'fxw-nw' ,'h-100vh']" >
    <div
      class="d-n@sm- peer peer-greed h-100 pos-r bgr-n bgpX-c bgpY-c bgsz-cv bg-image"
    >
      <div class="pos-a centerXY">
        <div
          class="bgc-white bdrs-50p pos-r"
          style="width: 120px; height: 120px"
        >
          <img
          width="100"
            class="pos-a centerXY"
            src="@/assets/static/images/logo/logo.svg"
            alt=""
          />
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-4 peer pX-40 pY-80 h-100 bgc-white scrollable pos-r"
      style="min-width: 320px"
    >
      <div>
        <div v-bind:class="[{rtl: $i18n.locale == 'ar'},'form-group' ,'mB-40']">
          <div class="peers ai-c jc-sb fxw-nw">
            <div class="peer">
              <h4 class="fw-300 c-grey-900">{{ $t("login") }}</h4>
            </div>
            <div class="peer"><lang v-bind:disabled="loading"/></div>
          </div>
        </div>
      </div>
      <form @submit="checkForm">
        <div class="form-group">
          <label for="phone" class="text-normal text-dark">{{$t("phone")}}</label>
          <input
            id="phone"
            type="phone"
            v-bind:class="[{'text-align':$i18n.locale == 'ar'},'form-control']"
            placeholder="09XXXXXXXX"
            v-model="phone"
            v-bind:disabled="loading"
          />
        </div>
        <div class="form-group">
          <label for="password" class="text-normal text-dark">{{$t("password")}}</label>
          <input
            id="password"
            type="password"
            v-bind:class="[{'text-align':$i18n.locale == 'ar'},'form-control']"
            v-bind:placeholder="$t('password')"
            v-model="password"
            v-bind:disabled="loading"
          />
        </div>
        <div v-show="error" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
        <div v-bind:class="[{rtl: $i18n.locale == 'ar'},'form-group']" v-show="!loading">
          <div class="peers ai-c jc-sb fxw-nw">
            <div class="peer">
              <div class="checkbox checkbox-circle checkbox-info peers ai-c">
                <input
                  type="checkbox"
                  id="inputCall1"
                  name="inputCheckboxesCall"
                  v-bind:class="[{'text-align':$i18n.locale == 'ar'},'peer']"
                  v-model="rememberMe"
                />
                <label for="inputCall1" class="peers peer-greed js-sb ai-c">
                  <span class="peer peer-greed">{{$t("rememberMe")}}</span>
                </label>
              </div>
            </div>
            <div class="peer">
              <button class="btn btn-primary" type="submit">{{$t("login")}}</button>
            </div>
          </div>
        </div>

        <loading v-show="loading" />
      </form>
    </div>
  </div>
</template>

<script>
import Lang from "../../components/Lang.vue";
export default {
  components: { Lang },
  name: "Login",
  data() {
    return {
      error: false,
      loading: false,
      message: "",
      phone: "",
      password: "",
      rememberMe: true,
    };
  },
  methods: {
    checkForm: function (e) {
      this.error = true;
      if (this.phone == "") {
        this.message = this.$t('enterPhone');
      } else if (this.password == "") {
        this.message = this.$t('enterPassword');
      } else if (!this.validPhone(this.phone)) {
        this.message = this.$t('unvalidNumber');
      } else {
        this.error = false;
        this.loading = true;
        this.login(this.phone, this.password);
      }

      e.preventDefault();
    },
    validPhone: function (phone) {
      var re = /^\d{8}$/;
      if (re.test(phone)) {
        return true;
      }
      return false;
    },
    login: function (phone, password) {
      this.$http
        .post("/backend/auth/login", {
          credential: "+974" + this.phone,
          password: this.password,
          firebase: 'sdfsdf'
        })
        .then(
          function (data) {
            switch (data.status) {
              case 200:
                const user = data.body;
                console.log('user ',user);
                sessionStorage.setItem('token' , user.token);
                sessionStorage.setItem('name',user.client.name);
                sessionStorage.setItem('role',user.client.role);
                this.success();
                if (this.rememberMe) {
                  localStorage.setItem('token' , user.token);
                  localStorage.setItem('name',user.client.name);
                  localStorage.setItem('role',user.client.role);
                } else {
                  localStorage.clear();
                }
                this.$router.push("/");
                break;
            }
          },
          (err) => {
            switch (err.status) {
              case 401:
                this.error = true;
                this.loading = false;
                this.message =this.$t("wrongPassword");
                break;
              case 500:
                this.error = true;
                this.loading = false;
                this.message =
                  this.$t("canNotAccess");
                break;

                default:
                  console.log(err);
                this.error = true;
                this.loading = false;
                this.message = this.$t("unexpectedError");
            }
          }
        );
    },
    success: function () {
      this.$toast.success(this.$t("success"), {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-image {
  background-image: url("~@/assets/static/images/bg.jpg");
}
.text-align {
  text-align: right;
}
</style>
